<template>
    <div class="corpARMoney">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['query','transactionReport','output','goBack']"
                @queryClick="loadData()"  
                @transactionReportClick="transactionReportClick"
                @outputClick="exportExcel()" 
                @goBackClick="back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="filter-box">
                    <div class="from-box">
                        <div class="lable-txt">账号类型:</div>
                        <div class="from-input">
                            <el-select v-model="kind">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="item in corpKinds"
                                    :key="item.Corp_KindID"
                                    :label="item.Corp_KindName"
                                    :value="item.Corp_KindID"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">账户检索:</div>
                        <key-board-input class="from-input search"
                            type="text" v-model="searchTxt" placeholder="账户编号、账户名称、联系电话模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                    </div>
                </div>
                <div class="table-box">
                    <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                        ref="tableEl"
                        border
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        show-summary
                        :summary-method="getSummaries"
                        @cell-click="orderDetail"
                        :data="pageTableData"
                    >
                        <el-table-column prop="Corp_Code" label="账户编号" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Corp_Name" label="账户名称" min-width="100" align="left">
                            <template #default="scope">
                                <span class="link">{{scope.row.Corp_Name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Corp_KindName" label="账户类型" min-width="80" align="left" ></el-table-column>
                        <el-table-column prop="Corp_Money" label="挂账余额" min-width="80" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="DelayDays" label="回款限制天数" min-width="85" data-format="number"></el-table-column>
                        <el-table-column prop="OldestDate" label="最早未回款账单日期" min-width="80" align="left" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column prop="OldestDayNum" label="最长回款延迟天数" min-width="70" data-format="number"></el-table-column>
                        <el-table-column prop="IsDelayDays" label="已超期" min-width="80" :formatter="(row, column, cellValue)=>{return cellValue?'是':'否'}"></el-table-column>
                        <el-table-column prop="Salesmanager" label="担保人" min-width="80" align="left" ></el-table-column>
                        <el-table-column prop="Link_Man" label="联系人" min-width="80" align="left" ></el-table-column>
                        <el-table-column prop="Corp_Tel" label="联系电话" min-width="80" align="left" ></el-table-column>
                        <el-table-column prop="Remark" label="备注" min-width="100" align="left"></el-table-column> 
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="filterData" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>pageTableData=list" 
                    :pageSizes="[20,30,50]"
                    :page-size="pageSize"
                    @sizeChange="(size)=>pageSize=size">
                </table-page-btn>
            </div>
        </div>
    </div>
</template>

<script>
/**AR账单 应收账户处理 */
export default {
    name:"corpARMoney",
    data(){
        let pageSize= this.$cacheData.get("corpARMoney_pageSize")||20;
        return {
            currentPage:1,
            pageSize:pageSize,
            //搜索数据
            searchTxt:"",
            //账单类型筛选
            kind:null,
            /** 账号类型信息 */
            corpKinds:null,
            /**账单信息 */
            tableData:[],
            pageTableData:[]
        }
    },
    computed:{
        /**筛选 */
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Corp_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Corp_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Corp_Tel||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.kind){
                data=data.filter(it=>it.Corp_KindID==this.kind);
            }
            return data;
        },
        /**合计数据 */
        summaryTableList(){
            let total={Corp_Money:0}
            this.filterData?.forEach((it)=>{
                total.Corp_Money+=it.Corp_Money;
            });
            return total;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("corpARMoney_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        
        const loading = this.$loading({
            text: "数据加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        Promise.all([this.loadARCorpKindInfo(false),this.loadData(false)]).then(()=>{
            loading.close();
        }).catch(err=>{
            console.log(err) // 失败 
        })
    },
    methods:{
        back(){
            this.$router.push('setup');
        },
        /**账户分类 */
        loadARCorpKindInfo(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            return this.$httpAES.post("Bestech.CloudPos.GetBestARCorpKindInfo", {}).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.corpKinds=d.ResponseBody;
                    console.log(d)
                }
            }).catch((e)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("账户分类加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**查询数据 */
        loadData(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            return this.$httpAES.post("Bestech.CloudPos.GetBestARCorpInfo", {}).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.tableData=d.ResponseBody;
                }
            }).catch((e)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**导出excel */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"应收账户信息",
                list:this.filterData
            })
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        //穿透
        orderDetail(row, column){
            if(column.property=="Corp_Name"){
                this.$router.push({name: 'corpARMoneyDetails', params:{id:row.Corp_AutoID,Corp_Name:row.Corp_Name,LastUpdated_Time:row.LastUpdated_Time}}); 
            }
        },
        /**跳转到流水报表 */
        transactionReportClick(){
            this.$router.push({name: 'transactionReport'}); 
        }
    }
}
</script>

<style lang="scss">
    @import './corpARMoney.scss'
</style>